:root {
  --primary: #7209b7;
  --secondary: #3a0ca3;
  --accent: #4cc9f0;
  --background: #0d1117;
  --text: #f8f9fa;
  --card-bg: #161b22;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  color: var(--text);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.app {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.coming-soon {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem;
}

.content {
  text-align: center;
  z-index: 1;
  max-width: 600px;
}

.title {
  font-size: 6rem;
  margin-bottom: 1.5rem;
}

.gradient-text {
  background: linear-gradient(45deg, var(--primary), var(--accent));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.message {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.sub-message {
  font-size: 1.2rem;
  color: #a8b2d1;
  line-height: 1.6;
}

.background-elements {
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.gradient-sphere {
  position: absolute;
  width: 600px;
  height: 600px;
  background: radial-gradient(circle, var(--primary) 0%, transparent 70%);
  border-radius: 50%;
  filter: blur(100px);
  opacity: 0.15;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.floating-elements {
  position: absolute;
  inset: 0;
}

.element {
  position: absolute;
  width: 80px;
  height: 80px;
  background: var(--card-bg);
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: var(--accent);
}

.keyboard {
  top: 20%;
  left: 15%;
  animation: float 4s ease-in-out infinite;
}

.mouse {
  top: 60%;
  right: 15%;
  animation: float 5s ease-in-out infinite;
}

.controller {
  bottom: 25%;
  left: 20%;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 4rem;
  }
  
  .message {
    font-size: 1.5rem;
  }
  
  .sub-message {
    font-size: 1rem;
  }
  
  .element {
    width: 60px;
    height: 60px;
  }
} 